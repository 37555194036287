.dynamic-text {
  position: relative;
  display: flex;

  .software {
    color: $color-4;
  }

  #text-target {
    display: flex;
    width: 300px;
    text-align: left;
    animation: color 30s alternate infinite;
    perspective: 200px;
    transform: translateX(26px);
  }
  .word {
    position: absolute;
  }
  @keyframes color {
    0% {
      color: $color-2;
    }
    50% {
      color: $color-4;
    }
    100% {
      color: $color-2;
    }
  }
  .letter {
    transform: translateZ(200px);
  }
  @keyframes anim {
    25% {
      transform: translateZ(0px);
      opacity: 1;
    }
    32% {
      opacity: 0;
      transform: translateZ(-280px) translateX(200px);
    }
  }
}
