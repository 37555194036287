@import './settings';
@import './components/navigation';
@import './pages/home';
@import './components/socialNetwork';
@import './components/scrollBottom';
@import './pages/contact';
@import './components/logo';
@import './pages/project';
@import './pages/notFound';
@import './components/mouse';
@import './components/dynamicText';


*{
    margin: 0;
}

