.logo {
  position: absolute;
  font-family: $font-2;
  font-size: 1.2rem;
  user-select: none;
 
 

  &:after {
    content: "";
    height: 24px;
    width: 24px;
    border: 1px $color-2 solid;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    left: -16px;
    z-index: -1;
  }
}