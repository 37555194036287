.contact {
  position: absolute;
  width: 100vw;
  height: 100vh;

  &:after {
    content: "";
    height: 700px;
    width: 700px;
    background: $color-3;
    border-radius: 50%;
    position: absolute;
    top: 150px;
    right: -460px;
    z-index: -10;
    animation: contactAnim 25s alternate infinite;

    @media screen and (max-width: 840px){
      height: 400px;
      width: 400px;
      top: 520px;
      right: -140px;
    }
    @media screen and (max-width: 430px){
      display: none;
    }

    @keyframes contactAnim {
      50% {
        transform: scale(1.4);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .navigation {
    @media screen and (max-width: 440px){
     display: none;
    }
  }
  .logo {
    top: 8px;
    right: 1rem;
  }

  // @media screen and (max-width: 1270px){
  //   grid-template-columns: repeat(1, 1fr);
  //   .map-sect{
  //       width: 100%;
  //       height: 50vh;
  //       margin-bottom: 1rem;
  //   }
  // }

  // .map-sect{
  //   // width: 100%;
  //   padding: 0.5rem;
  //   background-color:#6289e4 ;
  //   iframe{
  //       // width: 100%;
  //       // height: 100%;
  //   }
  // }

  

  .contact-form {
    display: flex;
    margin: 7% 0 0 24%;
    max-width: 50%;
    position: relative;
    max-width: 350px;

    @media screen and (max-width: 740px){
      margin: 2rem 0 0 30%;
    }
    @media screen and (max-width: 440px){
      margin-left: 2rem;
    }
    h2 {
      margin-bottom: 20px;

      &:after {
        content: "";
        height: 70px;
        width: 70px;
        border: 1px $color-2 solid;
        border-radius: 50%;
        position: absolute;
        top: -22px;
        left: 133px;
        z-index: -1;
      }
    }

    .form-content {
      display: grid;
      grid-template-rows: repeat(4, 1fr) 100px;

      input,
      textarea {
        background: none;
        font-family: $font-1;
        font-size: 1.2rem;
        border-bottom: 1px solid $color-2;

        @media screen and (min-width: 1200px){
          font-size: 1.5rem;
        }
      }
      input {
        padding: 24px 0 12px;
        width: 100%;

        @media screen and (max-width: 540px){
          width: 75%;
          padding: 12px 0 6px;
        }
      }
      .email-content {
        position: relative;
        width: 100%;
        border-bottom: 1px solid $color-2;

        @media screen and (max-width: 540px){
          width: 75%;
        }

        #not-mail {
          display: none;
          position: absolute;
          top: 8px;
          color: $red;
          right: 0;
          transform-origin: 50% 50%;
        }
        @keyframes dongle {
          0% {
            transform: translate(0px, 0px);
          }
          10% {
            transform: translate(-10px, 0px);
          }
          20% {
            transform: translate(10px, 0px);
          }
          30% {
            transform: translate(-10px, 0px);
          }
          40% {
            transform: translate(10px, 0px);
          }
          50% {
            transform: translate(-10px, 0px);
          }
          60% {
            transform: translate(10px, 0px);
          }
          70% {
            transform: translate(-10px, 0px);
          }
          80% {
            transform: translate(10px, 0px);
          }
          90% {
            transform: translate(-10px, 0px);
          }
          100% {
            transform: translate(0px, 0px);
          }
        }
        input {
          border: none;
        }
      }
      textarea {
        resize: none;
        padding: 24px 0;

        @media screen and (max-width: 540px){
          width: 75%;
          padding: 12px 0;
        }
      }
    }
    .error::placeholder {
      color: $red;
    }
    .form-message {
      margin-top: 10px;
      padding: 12px;
      opacity: 0;
      background: $color-2;
      transition: 0.3s ease;
      color: $color-1;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
    }
  }
  .contact-infos {
    position: absolute;
    bottom: 0;
    right: 0;
    display: grid;
    grid-template-rows: repeat(4, 23%) 12%;
    height: 70%;
    width: 30%;
    max-width: 500px;

    @media screen and (max-width: 740px){
      height: 200px;
      width: 100%;
      max-width: 100%;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: 
        'a b c'
        'd d d'
        'e e f';
    }
    @media screen and (max-width: 540px){
      grid-template-rows: repeat(3, 60px);
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: 
        'b b b c c c'
        'd d d d d d'
        'e e e e f f';
    }
    @media screen and (max-height: 500px){
      display: none;
    }
    @media screen and (min-width: 1200px){
      width: 35%;
      height: 83%;
    }

    h4 {
      color: $color-4;
      font-size: 1.3rem;
      font-weight: 200;
      margin-bottom: 10px;

      @media screen and (min-width: 1200px){
        font-size: 1.8rem;
      }
    }
    p {
      @media screen and (min-width: 1200px){
        font-size: 1.5rem;
      }
    }
    .email,
    .phone {
      @media screen and (max-height: 640px){
        display: none;
      }
    }
    .email .content p,
    .phone .content p {
      transition: 0.25s;

      &:hover {
        transform: translateX(5px);
      }
    }
    
    .address {
      position: relative;

      @media screen and (max-width: 540px){
       display: none;
      }
      .content {
        position: absolute;
        right: 10px;
      }
    }
    .phone {
      position: relative;

      @media screen and (max-width: 540px){
        text-align: center;
        font-size: .8rem;
        grid-area: b;
      }

      .content {
        position: absolute;
        left: 40%;
        transform: translateX(-50%);

        @media screen and (max-width: 540px){
          position: relative;
          margin: 0 auto;
          left: 0;
          transform: translateX(0);
        }
      }
    }
    .email {
      @media screen and (max-width: 540px){
        grid-area: c;
        font-size: .8rem;
        text-align: center;
      }
    }
    .social-network {
      position: relative;
      width: 80%;
      margin: 0 auto;

      @media screen and (max-width: 740px){
        width: 50%;
        grid-area: d;
      }
      @media screen and (min-width: 1200px){
        font-size: 2rem;
      }

      .content {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .credits {
      text-align: center;

      @media screen and (max-width: 740px){
        transform: translateY(20px);
        font-size: .7rem;
        margin-right: 1rem;
        grid-area: f;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
